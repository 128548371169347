// favoriteGames.js
import axios from "axios";
const state = {
  favoriteGames: {
    items: [],
    page: 1,
    pages: 1,
    size: 1,
    total: 1,
  },
  isNeedRequest: true,
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  APPEND_CURRENT_FAVORITE_GAMES: (state, games) => {
    state.favoriteGames.items = state.favoriteGames.items.concat(games.items);

    state.favoriteGames.page = games.page;
    state.favoriteGames.pages = games.pages;
    state.favoriteGames.size = games.size;
    state.favoriteGames.total = games.total;
  },
};

const getters = {
  getFavoriteGames: (state) => state.favoriteGames,
};

const actions = {
  async awaitGetFavoriteGames({ commit, state }, params) {
    // let params = filters;
    // if (!filters.provider) {
    //   params = {
    //     ...filters,
    //     provider: state.currentProvider,
    //   };
    // }
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    // commit("set", { type: "currentProvider", items: params.provider });
    try {
      const response = await axios.get(
        `/main-api/games/my/filter?is_favourite=true&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      if (response.status === 200) {
        const { items, ...responseWithoutItems } = response.data;
        const paggination = responseWithoutItems;
        const games = items;
        const result = {
          items: games,
          page: paggination.page,
          pages: paggination.pages,
          size: paggination.size,
          total: paggination.total,
        };
        if (params.page >= 2) {
          commit("APPEND_CURRENT_FAVORITE_GAMES", result);
        } else {
          commit("set", {
            type: "favoriteGames",
            items: result,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching games:", error);
      throw error;
    }
  },
  async awaitToggleFavoriteGame(
    { commit, state },
    { id: game_id, is: is_favorite }
  ) {
    let method = "post";
    if (!is_favorite) {
      method = "delete";
    }
    try {
      const response = await axios({
        method: method,
        url: `/main-api/games/favourites/${game_id}`,
        headers: { Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      });
      if (response.status === 200) {
        commit("set", {
          type: "games",
          items: response.data,
        });
      }
    } catch (error) {
      console.error("Error toggling favorite game:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
