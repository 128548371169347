// plugins/i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import { mainDomain } from "@/utils/variables";
Vue.use(VueI18n);

let i18nInstance = null;

export const i18nPromise = async (langKey) => {
  const domain = mainDomain;
  const currentLang = localStorage.getItem("locale") || "en";
  const lang = langKey || currentLang;
  if (i18nInstance && i18nInstance.locale === lang) {
    return i18nInstance;
  }
  async function fetchTranslations(lang) {
    const response = await axios.get(
      `/main-api/translations/${lang}?domain=${domain}`
    );
    return {
      messages: response.data,
      status: response.status,
    };
  }

  async function fetchTranslationsFallback(lang) {
    const response = await axios.get(
      `/main-api/translations/en?domain=${domain}`
    );
    return {
      messages: response.data,
      status: response.status,
    };
  }

  try {
    const { messages, status } = await fetchTranslations(lang);

    if (status === 200) {
      i18nInstance = new VueI18n({
        locale: lang,
        fallbackLocale: "en",
        messages: {
          [lang]: messages,
        },
      });

      i18nInstance.locale = lang;
      return i18nInstance;
    }
  } catch (error) {
    if (error.response && error.response.status === 409) {
      try {
        const { messages } = await fetchTranslationsFallback("en");

        i18nInstance = new VueI18n({
          locale: "en",
          fallbackLocale: "en",
          messages: {
            en: messages,
          },
        });

        i18nInstance.locale = "en";
        return i18nInstance;
      } catch (error) {
        console.error("Failed to initialize i18n:", error);
        throw error;
      }
    }

    console.error("Failed to initialize i18n:", error);
    throw error;
  }
};
export default i18nInstance;
