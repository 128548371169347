//localization.js;
import axios from "axios";
import { i18nPromise } from "../../plugins/i18n";
import { mainDomain } from "@/utils/variables";
const state = {
  domain: mainDomain,
  localizations: [],
  currentJSON: "",
};

const mutations = {
  SET_LOCALIZATIONS(state, locals) {
    state.localizations = locals;
  },
  SET_SELECT_LOCAL(state, json) {
    state.currentJSON = json;
  },
};

const getters = {
  getLocalizations: (state) => state.localizations,
  getJSON: (state) => state.currentJSON,
};

const actions = {
  async awaitGetLocalizations({ state, commit }) {
    return axios
      .get(`/main-api/translations/public?domain=${state.domain}`)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_LOCALIZATIONS", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async awaitGetSelectLocal({ commit }, code) {
    i18nPromise(code);
    console.log("i18nPromise", i18nPromise);
    localStorage.setItem("locale", code);
    // return axios
    //   .get(`/main-api/translations/${code}`)
    //   .then((response) => {
    //     if (response.status === 200) {
    //       commit("SET_SELECT_LOCAL", response.data);
    //       // i18nPromise.setLocaleMessage(`${code}`, response.data);
    //       // i18nPromise.locale = code;
    //       // console.log("i18nPromise", i18nPromise.messages);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
